import React, { Component } from "react";
// Components
import Helmet from "react-helmet";
import Dropdown from "components/Dropdown";
import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";
import { graphql } from "gatsby";
import Hero from "slices/Hero";
import BigCallout from "slices/BigCallout";
import EmailContactGrid from "components/EmailContactGrid";
import LinkGrid from "components/LinkGrid";
import getPageMeta from "utils/getPageMeta";

import { renderText } from "utils/renderHelpers";

import styles from "./ContactPage.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);
const regionSortOrder = ["us", "canada", "europe", "asia"];
const officeOrder = [
  "new-york",
  "irvine",
  "los-angeles",
  "toronto",
  "vancouver",
  "montreal",
  "quebec-city",
  "london",
  "singapore",
];

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.offices = this.props.data.offices.edges.map((edge) => edge.node);

    this.offices.sort((a, b) => {
      return officeOrder.indexOf(a.uid) - officeOrder.indexOf(b.uid);
    });

    this.ddRegions = [
      { name: "Worldwide", value: null },
      { name: "U.S.", value: "us" },
      { name: "Canada", value: "canada" },
      { name: "Europe", value: "europe" },
      { name: "Asia", value: "asia" },
    ];

    this.state = {
      regionFilter: null,
      offices: this.getFilteredOffices(null),
    };

    this.hero = props.data.page.data.body.find(
      (item) => item.slice_type === "hero"
    );
    this.hero.primary.theme = "red";
    this.hero.large = true;

    this.influencers = props.data.page.data.body.find(
      (item) => item.slice_type === "influencer_contacts"
    );
    this.callout = props.data.page.data.body.find(
      (item) => item.slice_type === "callout"
    );
  }

  sortByRegion(offices) {
    let arr = [];
    let sortArr = Array.from(regionSortOrder);
    while (sortArr.length) {
      let sortField = sortArr.shift();
      arr = arr.concat(
        offices.filter((office) => office.tags.includes(sortField))
      );
    }
    return arr;
  }

  getFilteredOffices(value) {
    if (!value) {
      return this.offices;
    }
    let filters = [value];
    let result = this.offices
      .map((node) => {
        for (let i = 0; i < filters.length; i++) {
          if (node.tags.includes(filters[i])) {
            return node;
          }
        }
        return null;
      })
      .filter(Boolean);

    return this.sortByRegion(result);
  }

  onRegionChange = ({ value }) => {
    if (this.state.regionFilter === value) {
      return;
    }
    this.setState({
      regionFilter: value,
      offices: this.getFilteredOffices(value),
    });
  };

  render() {
    const { page, globals } = this.props.data;
    const { title } = page.data;

    const { offices } = this.state;

    const {
      north_america_contact,
      // north_america_phone,
      international_contact,
      // international_phone
    } = globals.data;

    const naContact = north_america_contact.document
      ? north_america_contact.document[0]
      : null;

    // const naPhone =
    //   north_america_phone && north_america_phone.length > 0
    //     ? north_america_phone
    //     : naContact && naContact.data.phone_number
    //     ? naContact.data.phone_number
    //     : null;

    const intContact = international_contact.document
      ? international_contact.document[0]
      : null;

    // const intPhone =
    //   international_phone && international_phone.length > 0
    //     ? international_phone
    //     : intContact && intContact.data.phone_number
    //     ? intContact.data.phone_number
    //     : null;

    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        <Hero {...this.hero} />
        <LinkGrid
          offset={true}
          nodes={offices}
          noResultsMessage={`No offices found.`}
          preGridChildren={
            <Container className={styles.gridHeader}>
              <Columns mobile multiline>
                {naContact && (
                  <Columns.Column
                    mobile={{ size: 12 }}
                    tablet={{ size: 12 }}
                    desktop={{ size: 4 }}
                    widescreen={{ size: 4 }}
                    fullhd={{ size: 3 }}
                    className={styles.heroColumn}
                  >
                    <h4>North America</h4>
                    <p>{naContact.data.name.text}</p>
                    {/* {naPhone && (
                        <React.Fragment>
                          <a className={styles.email} href={`tel:${naPhone}`}>
                            {naPhone}
                          </a>
                          <br />
                        </React.Fragment>
                      )} */}
                    {renderText(naContact.data.email, `a`, styles.email, {
                      target: `_blank`,
                      href: `mailto:${naContact.data.email.text}`,
                    })}
                  </Columns.Column>
                )}
                {intContact && (
                  <Columns.Column
                    mobile={{ size: 12 }}
                    tablet={{ size: 12 }}
                    desktop={{ size: 4 }}
                    widescreen={{ size: 4 }}
                    fullhd={{ size: 3 }}
                    className={styles.heroColumn}
                  >
                    <h4>EMEA / APAC</h4>
                    <p>{intContact.data.name.text}</p>
                    {/* {intPhone && (
                        <React.Fragment>
                          <a className={styles.email} href={`tel:${intPhone}`}>
                            {intPhone}
                          </a>
                          <br />
                        </React.Fragment>
                      )} */}
                    {renderText(intContact.data.email, `a`, styles.email, {
                      target: `_blank`,
                      href: `mailto:${intContact.data.email.text}`,
                    })}
                  </Columns.Column>
                )}
                <Columns.Column
                  mobile={{ size: 12 }}
                  widescreen={{ size: 4 }}
                  className={cx({ heroColumn: true, filter: true })}
                >
                  <h4>Show offices</h4>
                  <Dropdown
                    items={this.ddRegions}
                    onChange={this.onRegionChange}
                  />
                </Columns.Column>
              </Columns>
            </Container>
          }
        />
        {this.influencers && <EmailContactGrid {...this.influencers} />}
        <BigCallout {...this.callout} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    offices: allPrismicOffice(
      filter: { uid: { regex: "/^((?!_template).)*$/" } }
      sort: { fields: tags }
    ) {
      edges {
        node {
          uid
          tags
          slug
          data {
            name {
              text
            }
            country {
              text
            }
            city {
              text
            }
            thumbnail {
              url
              dimensions {
                width
                height
              }
            }
            phone {
              text
            }
            email {
              text
            }
            location {
              latitude
              longitude
            }
            body {
              ... on PrismicOfficeBodyInquiryListings {
                slice_type
                items {
                  person {
                    document {
                      uid
                      data {
                        name {
                          text
                        }
                        email {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    page: prismicContactPage(uid: { eq: $uid }) {
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url

            dimensions {
              width
              height
            }
          }
          facebook {
            url

            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicContactPageBodyHero {
            slice_type
            primary {
              heading {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
          }
          ... on PrismicContactPageBodyInfluencerContacts {
            slice_type
            primary {
              heading {
                html
                text
              }
            }
            items {
              heading {
                html
                text
              }
              email {
                link_type
                url
                target
              }
            }
          }
          ... on PrismicContactPageBodyCallout {
            id
            slice_type
            primary {
              theme
              button_label
              heading {
                text
              }
              link {
                document {
                  slug
                  data {
                    title {
                      text
                    }
                  }
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }

    globals: prismicFoot {
      data {
        north_america_contact {
          document {
            data {
              name {
                text
              }
              email {
                text
              }
            }
          }
        }
        north_america_phone
        international_contact {
          document {
            data {
              name {
                text
              }
              email {
                text
              }
            }
          }
        }
        international_phone
      }
    }
  }
`;

export default ContactPage;
